import Cards from "./Cards";
import { motion } from "framer-motion";
import evWebsiteImg from "../img/designs/ev-website.png";
import mockUpImg from "../img/designs/mock-up.png";
import miraclesImg from "../img/designs/miracles.png";
import adhocImg from "../img/designs/adhoc-project.png";
import csrImg from "../img/designs/csr-benefits.png";
import oneImg from "../img/designs/one-planet.png";
import barImg from "../img/designs/bar.png";
import pestImg from "../img/designs/pest-web.png";
import { useRef, useEffect, useState } from "react";

export default function Designs() {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <div className="projects-container">
      <div
        className={`card-container ${isVisible ? "observer-visible" : ""}`}
        ref={elementRef}
      >
        <motion.div
          className="motion-div"
          initial={{ x: "-100%" }}
          animate={{ x: "0%" }}
        >
          <Cards
            link="https://samplebarmenu.netlify.app/"
            src={barImg}
            info="Bar Menu"
            about="Transform your business with our custom digital menus, designed to enhance your customers' dining experience effortlessly."
          />
        </motion.div>
      </div>

      <motion.div
        className="motion-div"
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
      >
        <Cards
          link="https://trapcodes.com/"
          src={pestImg}
          info="Pest Website"
          about="Take your business to the next level with custom website development services, creating impactful digital experiences."
        />
      </motion.div>

      <motion.div
        className="motion-div"
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
      >
        <Cards
          link="https://evchargerinstallnj.com/"
          src={evWebsiteImg}
          info="Ev-Charger"
          about="A company specializing in installing vehicle chargers requested a website, which I developed using WordPress, ensuring it was optimized for mobile responsiveness."
        />
      </motion.div>

      <motion.div
        className="motion-div"
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
      >
        <Cards
          link="https://mockup-mikedev.netlify.app/"
          src={mockUpImg}
          info="Portfolio Website"
          about="I came across this cool website design and decided to build it myself—lots of CSS fun involved!"
        />
      </motion.div>

      <motion.div
        className="motion-div"
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
      >
        <Cards
          link="https://nexustential.github.io/MiraclesMassage"
          src={miraclesImg}
          info="Miracles Massage"
          about="This was one of my first projects, after I was done with my Bootcamp, we created this static website really well designed, I was in charge of the navbar and the mobile-friendly feature."
        />
      </motion.div>

      <motion.div
        className="motion-div"
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
      >
        <Cards
          link="https://adhoc-project.netlify.app/"
          src={adhocImg}
          info="Adhoc Project"
          about="This is just a HTML, CSS project I decided to make a long time ago for practicing purposes, I been modifying the website little by little."
        />
      </motion.div>

      <motion.div
        className="motion-div"
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
      >
        <Cards
          link="https://csrbenefitshub.com"
          src={csrImg}
          info="CSR benefits"
          about="I contributed to a WordPress project utilizing Avada theme. This experience was enjoyable and provided valuable insights into the drag-and-drop interface of WordPress"
        />
      </motion.div>

      <motion.div
        className="motion-div"
        initial={{ x: "-100%" }}
        animate={{ x: "0%" }}
      >
        <Cards
          link="https://oneplanet-onepeople.com/"
          src={oneImg}
          info="One planet"
          about="I contributed to another WordPress project by developing various website sections and converting PDF image files into functional web pages."
        />
      </motion.div>
    </div>
  );
}
